<template>
  <div class="page" :loaded="Boolean(podcast)">
    <section class="podcast" v-if="podcast">
      <div class="wrapper">
        <div class="container">
          <img
            :src="podcast.image"
            :alt="podcast.title"
            class="podcast-cover"
          />
          <div class="podcast-details">
            <h3>{{ podcast.title }}</h3>
            <p>{{ podcast.description }}</p>
            <div class="podcast-sources">
              <a
                v-if="podcast.platforms.spotify"
                :href="podcast.platforms.spotify"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/assets/images/spotify.svg"
                  alt="Listen on Spotify"
                />
              </a>
              <a
                v-if="podcast.platforms.itunes"
                :href="podcast.platforms.itunes"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/itunes.svg" alt="Listen on iTunes" />
              </a>
              <a
                v-if="podcast.platforms.soundcloud"
                :href="podcast.platforms.soundcloud"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/soundcloud.svg" alt="Listen on Soundcloud" />
              </a>
              <a
                v-if="podcast.platforms.youtube"
                :href="podcast.platforms.youtube"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/youtube.svg" alt="Listen on YouTube" />
              </a>
            </div>
            <div class="share" v-if="podcast.podcast">
              <h5>Share {{ podcast.podcast.title }}</h5>
              <div class="socials">
                <a
                  :href="`mailto:?subject=${podcast.title}&body=${podcastLink}`"
                  class="social"
                  target="_blank"
                >
                  <Icon icon="envelope" type="far" size="small" rounded />
                </a>
                <a
                  :href="`https://www.facebook.com/sharer/sharer.php?u=${podcastLink}`"
                  class="social"
                  target="_blank"
                >
                  <Icon icon="facebook-f" size="small" rounded />
                </a>
                <a
                  :href="`https://twitter.com/home?status=${podcast.title} ${podcastLink}`"
                  class="social"
                  target="_blank"
                >
                  <Icon icon="twitter" size="small" rounded />
                </a>
                <a
                  :href="`https://www.linkedin.com/shareArticle?mini=true&url=${podcastLink}&title=${podcast.title}`"
                  class="social"
                  target="_blank"
                >
                  <Icon icon="linkedin-in" size="small" rounded />
                </a>
                <a
                  ref="podcastLink"
                  :active="copied"
                  :data-clipboard-text="podcastLink"
                  class="social"
                >
                  <Icon icon="link" type="fas" size="small" rounded />
                  <div class="valid">Copied!</div>
                </a>
              </div>
              <div class="podcast-about">
                <h5>About</h5>
                <p>{{ podcast.podcast.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="podcast-episodes" v-if="podcast && episodes">
      <div class="wrapper">
        <div class="container">
          <div class="episodes">
            <div
              class="episode"
              v-for="(episode, index) in episodes"
              :key="episode.guid"
            >
              <img
                class="episode-cover"
                :src="episode.cover || require('@/assets/images/wb_bg_podcast-episode.jpg')"
              >
              <div class="episode-details">
                <h4 class="episode-title">{{ episode.title }}</h4>
                <div class="episode-date">
                  <DateFormat :date="episode.isoDate" format="MMMM D, Y" />
                  by <b>WestBIC</b>
                </div>
                <div class="episode-description" v-html="episode.content"></div>
                <a class="btn medium ghost episode-link" @click="playEpisode(episode, index)">
                  <Icon icon="play" type="fas" />
                  Listen now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BpsImage from "./BpsImage.vue";
import DateFormat from "./DateFormat.vue";
import Icon from "./Icon.vue";
import Clipboard from "clipboard";

export default {
  name: "Podcast",
  components: {
    BpsImage,
    DateFormat,
    Icon,
  },
  data() {
    return {
      podcast: null,
      episodes: null,
      loading: false,
      error: false,
      copied: false,
      success: false,
      newsletterEmail: "",
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    functions() {
      return this.$store.state.firebase.functions;
    },
    podcastLink() {
      return `https://www.westbic.ie${this.$route.path}`;
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
  },
  methods: {
    getItems() {
      if (this.podcastRef) this.podcastRef();
      if (this.episodesRef) this.episodesRef();
      this.podcastRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("podcast")
        .onSnapshot((podcast) => {
          this.podcast = podcast.exists ? podcast.data() : null;
          if (this.podcast && this.podcast.podcast) {
            this.$nextTick().then(() => {
              const clip = new Clipboard(this.$refs.podcastLink);
              clip.on("success", () => {
                clearTimeout(this.copiedTO);
                this.copied = true;
                this.copiedTO = setTimeout(() => (this.copied = false), 1500);
              });
            });
          }
        });
      this.episodesRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}podcasts`)
        .orderBy("pubDate", "desc")
        .onSnapshot((episodes) => {
          this.episodes = episodes.docs.map((episode) => episode.data());
        });
      this.functions().httpsCallable("getPodcast")({ test: this.testMode });
    },
    playEpisode(episode, index) {
      window.dispatchEvent(
        new CustomEvent("playPodcast", {
          detail: {
            current: episode,
            index,
            episodes: this.episodes,
          },
        })
      );
    },
  },
  mounted: function () {
    this.getItems();
  },
  beforeDestroy: function () {
    if (this.podcastRef) this.podcastRef();
    if (this.episodesRef) this.episodesRef();
  },
};
</script>

<style scoped>
.podcast {
  padding: 64px 0;
  margin-bottom: 64px;
  background: #fafafa;
}

.podcast .container {
  display: flex;
}

.podcast .podcast-details {
  position: relative;
  margin: 0 0 0 80px;
}

.podcast .container .podcast-cover {
  width: 560px;
  height: 560px;
  object-fit: cover;
  border-radius: 16px;
}

.podcast .podcast-details:before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: 36px;
  width: 8px;
  height: 170px;
  background: url(../assets/images/wb_o_separator.svg) no-repeat center/contain;
}

.podcast .podcast-details h3 {
  margin-bottom: 16px;
  color: #0A7E8C;
}

.podcast .share > h5 {
  color: #0A7E8C;
  margin-bottom: 24px;
}

.podcast .podcast-sources {
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0 40px;
}

.podcast .podcast-sources a {
  display: block;
  margin-right: 24px;
  margin-bottom: 12px;
}

.podcast .podcast-sources a img {
  height: 40px;
}

.podcast .socials {
  display: flex;
}

.podcast .socials .social {
  margin-right: 8px;
}

.podcast .podcast-about h5 {
  margin: 40px 0 16px;
}

.podcast .podcast-about p {
  font-size: 18px;
}

.podcast .podcast-details .btn {
  margin-top: 40px;
}

.episodes {
  display: flex;
  flex-wrap: wrap;
}

.episodes .episode {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(50% - 24px);
  border-radius: 16px;
  border: 1px solid rgba(101, 102, 98, 0.08);
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  margin: 0 12px 64px;
  transition: all .2s ease-out;
  overflow: hidden;
}

.episodes .episode img {
  display: block;
  width: 100%;
  height: 300px;
  object-fit: cover;
  flex-shrink: 0;
}

.episodes .episode .episode-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 48px 48px;
  height: 100%;
}

.episodes .episode h4 {
  margin-bottom: 12px;
}

.episodes .episode .episode-date {
  font-size: 20px;
  color: #656662;
}

.episodes .episode .episode-description {
  margin: 24px 0;
  flex: 1;
}

.episodes .episode .episode-description >>> a {
  color: #1dacd6;
  text-decoration: underline;
}

#app[no-touch] .episodes .episode:hover {
  box-shadow: 0 24px 32px rgba(52, 52, 52, 0.12);
  transform: translateY(-8px);
}

@media screen and (max-width: 1024px) {
  .episodes .episode {
    width: 100%;
    margin: 0 0 48px;
  }
  .podcast .container {
    flex-direction: column;
  }
  .podcast .container .podcast-cover {
    width: 100%;
    height: 320px;
    object-fit: cover;
  }
  .podcast .podcast-details {
    margin: 32px 96px 0;
  }
}

@media screen and (max-width: 600px) {
  .podcast .podcast-details {
    margin: 32px 0 0;
  }
  .podcast .container {
    padding: 24px 24px 48px;
  }
  .podcast .container .podcast-cover {
    width: 100%;
    min-width: 0;
  }
  .podcast .podcast-sources a {
    margin-right: 8px;
  }
  .podcast .podcast-sources a:last-child {
    margin-right: 0;
  }
  .podcast .podcast-details:before {
    display: none;
  }
  .episodes .episode .episode-details {
    padding: 24px 24px 48px;
  }
  .episodes .episode .episode-date {
    font-size: 16px;
  }
  .episodes .episode img {
    height: 220px;
  }
}
</style>
